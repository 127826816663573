<template>
  <div class="legal-page-business-card">
    <material-app-card
      :color="color"
      icon="mdi-domain"
      title="BUSINESS CERTIFICATIONS/LICENSES"
    >
      <div class="intro">
        Follow three steps to download Certificates or Licenses：
      </div>
      <v-layout wrap fill-height class="download-form pa-3">
        <div style="width: 100%">
          <div class="step step-1 mt-2 mb-2 ml-3 mr-4">
            <div class="step-label">Step 1. Choose a certificate or license you need: </div>
            <v-select
              class="mb-3 pt-0"
              v-model="document.name"
              :items="docNames"
              hide-details
              :placeholder="docNames.length < 1 ? 'Loading' : 'Please Select'"
              :disabled="docNames.length < 1"
              :loading="docNames.length < 1"
            >
            </v-select>
          </div>
          <div class="step step-2 mt-2 mb-2 ml-3 mr-4">
            <div :class="{'glass': !document.name}">
              <div class="step-label pt-0">Step 2. Specify the usage of this certificate or license: </div>
              <v-select
                class="mb-3 pt-0"
                v-model="document.purpose"
                :items="docPurposes"
                hide-details
                placeholder="Please Select"
                :disabled="!document.name"
                :loading="!document.name"
              ></v-select>
            </div>
          </div>
          <div class="step step-3 mt-2 mb-2 ml-3 mr-4">
            <div :class="{'glass': !isLink(docLink)}">
              <div class="step-label">Step 3. Preview and download. </div>
              <v-btn color="primary" light
                :disabled="!isLink(docLink)"
                :loading="downloading"
                @click="downloadFile"
              >
                <v-icon size="30">mdi-download</v-icon>
                <div class="btn-label ml-1">Download Document</div>
              </v-btn>
            </div>
          </div>  
        </div>     
      </v-layout>
      <div v-for="i in 2" :key="'legal_page_business_for_more_' + i" 
        :class="{
          'for-more mt-3 mr-2 mb-5': true,
          'abs-bottom mb-3': i == 1,
          'glass': i == 2
        }"
      >
        <div>If you need any other certificates or licenses, please seek for proper approval</div>
      </div>
    </material-app-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { xPost } from '@/functions/http/axios'
import { setTimeout } from 'timers';

export default {
  props: {
    color: {
      type: String,
      default: 'primary'
    }
  },
  data () {
    return {
      document: {
        name: null,
        purpose: null
      },

      filesMatrix: [],
      downloading: false
    }
  },
  computed: {
    ...mapGetters('user', [
      'loginStatus'
    ]),
    docNames () {
      try {
        return this.filesMatrix.filter((el, i) => i > 0 && !!el.find(el2 => this.isLink(el2))).map(el => el[1])
      } catch (err) {
        return []
      }
    },
    docPurposes () {
      try {
        if (!this.document.name) { return [] }
        let x = this.filesMatrix.findIndex(el => el[1] === this.document.name)
        return this.filesMatrix[0].filter((el, i) => this.isLink(this.filesMatrix[x][i]))
      } catch (err) {
        return []
      }
    },
    docLink () {
      try {
        if (!this.document.name || !this.document.purpose) { return null }
        let x = this.filesMatrix.findIndex(el => el[1] === this.document.name)
        let y = this.filesMatrix[0].findIndex(el => el === this.document.purpose)
        return this.filesMatrix[x][y]
      } catch (err) {
        // console.log(err)
        return null
      }
    }
  },
  methods: {
    async getFiles () {
      try {
        const res = await xPost(
          'legal_business',
          'getFiles',
          {
            loginStatus: this.loginStatus
          }
        )
        if (res.data.status === 200) {
          this.filesMatrix = res.data.list
        }
      } catch (err) {
        console.log('Error [get business file]: ')
        // console.log(err)
      }
    },
    isLink (value) {
      try {
        return (
          ((value || '').toString().indexOf('https://nielsenenterprise-my.sharepoint.com/') === 0) ||
          (value || '').toString().substring(0, 32) === 'https://drive.google.com/file/d/' ||
          (value || '').toString().substring(0, 46) === 'https://drive.google.com/a/nielsen.com/file/d/' ||
          (value || '').toString().substring(0, 48) === 'https://drive.google.com/a/nielseniq.com/file/d/'
        )
      } catch (err) {
        return false
      }
    },
    async downloadFile () {
      this.downloading = true
      let downLink = this.docLink.toString()
      if (downLink.indexOf('https://nielsenenterprise-my.sharepoint.com/') === 0) {
        downLink = downLink
          .replace('onedrive.aspx', 'download.aspx')
          .replace('id=', 'SourceUrl=')
        window.location.href = downLink
      } else if (downLink.indexOf('https://drive.google.com/file/d/') === 0) {
        downLink = downLink
          .replace('https://drive.google.com/file/d/', 'https://drive.google.com/uc?export=download&id=')
          .replace('/view?usp=sharing', '')
        window.location.href = downLink
      } else {
        window.open(downLink)
      }
      setTimeout(() => {
        this.downloading = false
      }, 3000);
      try {
        const res = await xPost(
          'legal_business',
          'download',
          {
            loginStatus: this.loginStatus,
            document: this.document,
            link: this.docLink.toString()
          }
        )
        if (res.data.status === 200) {}
      } catch (err) {
        console.log('Error [download log]: ')
        // console.log(err)
      }
    }
  },
  created () {
    this.getFiles()
  }
}
</script>

<style lang="sass">
  .legal-page-business-card
    height: calc(100% - 48px)
    text-align: left

    .v-card
      height: 100%
      
      .v-card__text
        height: calc(100% - 64px)
        position: relative
    
    // .intro
    //   font-weight: bold

    // .step-label
    //   font-weight: bold

    .download-form
      .v-btn
        margin-top: 4px
        padding: 4px 18px 4px 9px

      .step
        float: left
        width: 400px
        max-width: calc(100% - 32px)
        
        .v-select__selections
          width: calc(100% - 28px)

          .v-select__selection
            width: calc(100% - 28px)
            overflow: hidden
            text-overflow: ellipsis    
            white-space: nowrap
            display: block

    // .for-more}

    .abs-bottom
      font-weight: bold
      position: absolute
      bottom: 2px

    .glass
      opacity: 0
      -webkit-user-select: none
      -moz-user-select: none
      -ms-user-select: none
      user-select: none
</style>
