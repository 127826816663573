<template>
  <v-layout wrap fill-height>
    <v-flex
      xs12
      pt-0 pb-0
    >
      <legal-page-business-card>
      </legal-page-business-card>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapActions } from 'vuex'
import LegalPageBusinessCard from "@/components/app/legal-page/business-card";

export default {
  components: {
    LegalPageBusinessCard
  },
  methods: {
    ...mapActions('app', ['setMainTitle'])
  },
  created () {
    this.setMainTitle('Business Certifications/Licenses')
  }
}
</script>

<style>

</style>
